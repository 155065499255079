import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String };

  static targets = [
    "errorMessage",
    "zipCode",
    "street",
    "complement",
    "neighborhood",
    "city",
    "state"
  ];

  async search() {
    const response = await fetch(this.searchCepUrl());
    this.handleSearchResponse(response);
  }

  async handleSearchResponse(response) {
    if (response.ok) {
      this.updateFields(await response.json());
      this.errorMessageTarget.classList.add("hidden");
    } else {
      this.errorMessageTarget.classList.remove("hidden");
    }
  }

  searchCepUrl() {
    const url = new URL(this.urlValue);
    url.searchParams.append("zip_code", this.zipCodeTarget.value);
    return url;
  }

  updateFields(cepData) {
    this.streetTarget.value = cepData.street;
    this.complementTarget.value = cepData.complement;
    this.neighborhoodTarget.value = cepData.neighborhood;
    this.cityTarget.value = cepData.city;
    this.stateTarget.value = cepData.state;
  }
}
